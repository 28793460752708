const categoryColors = {
    'Fiction': '#CC7178',
    'Mystery': '#08605F',
    'Thriller': '#685369',
    'Crime': '#30011E',
    'Romance': '#FF69B4',
    'Fantasy': '#177E89',
    'Science Fiction': '#ADD8E6',
    'Horror': '#90323D',
    'Historical Fiction': '#558564',
    'Adventure': '#C6D8AF',
    'Dystopian': '#FF4500',
    'Young Adult': '#66CED6',
    "Children's Books": '#8DA1B9',
    'Graphic Novels': '#BC8034',
    'Comics': '#C3423F',
    'Poetry': '#EE82EE',
    'Manga': '#FFC0CB',
    'Non-Fiction': '#808080',
    'Biography': '#FF1493',
    'Autobiography': '#FDF7FA',
    'Memoir': '#A8DCD9',
    'Personal Development': '#FFA07A',
    'Psychology': '#9ACD32',
    'Science/Nature': '#00CED1',
    'History': '#6A5ACD',
    'Philosophy': '#D2691E',
    'Politics/Government': '#1E90FF',
    'Religion/Spirituality': '#FF8C00',
    'Travel': '#32CD32',
    'Cookbooks/Food': '#FF4500',
    'Health/Fitness': '#2E8B57',
    'Art/Photography': '#8B008B',
    'Music': '#FCB97D',
    'Business/Entrepreneurship': '#4682B4',
    'Economics': '#008080',
    'Technology/Computer Science': '#A7A5C6',
    'Parenting/Family': '#9370DB',
    'Relationships': '#FF6347',
    'Sports': '#008000',
    'True Crime': '#4B0082',
    'Essays': '#696969',
    'Anthologies': '#FFD700',
    'Reference/Guides': '#808000',
    'Education': '#8797B2',
    'Environmental/Climate': '#2E8B57',
    'Cultural/Social Issues': '#EDD892',
    'Philosophy of Life': '#D2691E',
    'Critiques on Literature': '#696969',
    'Short Story Collections': '#FFD700',
    'Classic Literature': '#EFA48B',
}

export default categoryColors;