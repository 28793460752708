const BookCategories = {
    FICTION: 'Fiction',
    MYSTERY: 'Mystery',
    THRILLER: 'Thriller',
    CRIME: 'Crime',
    ROMANCE: 'Romance',
    FANTASY: 'Fantasy',
    SCIENCE_FICTION: 'Science Fiction',
    HORROR: 'Horror',
    HISTORICAL_FICTION: 'Historical Fiction',
    ADVENTURE: 'Adventure',
    DYSTOPIAN: 'Dystopian',
    YOUNG_ADULT: 'Young Adult',
    CHILDRENS_BOOKS: "Children's Books",
    GRAPHIC_NOVELS: 'Graphic Novels',
    COMICS: 'Comics',
    POETRY: 'Poetry',
    NON_FICTION: 'Non-Fiction',
    MANGA: 'Manga',
    BIOGRAPHY: 'Biography',
    AUTObIOGRAPHY: 'Autobiography',
    MEMOIR: 'Memoir',
    PERSONAL_DEVELOPMENT: 'Personal Development',
    PSYCHOLOGY: 'Psychology',
    SCIENCE_NATURE: 'Science/Nature',
    HISTORY: 'History',
    POLITICS_GOVERNMENT: 'Politics/Government',
    PHILOSOPHY: 'Philosophy',
    RELIGION_SPIRITUALITY: 'Religion/Spirituality',
    TRAVEL: 'Travel',
    COOKBOOKS_FOOD: 'Cookbooks/Food',
    HEALTH_FITNESS: 'Health/Fitness',
    ART_PHOTOGRAPHY: 'Art/Photography',
    MUSIC: 'Music',
    BUSINESS_ENTREPRENEURSHIP: 'Business/Entrepreneurship',
    ECONOMICS: 'Economics',
    TECHNOLOGY_COMPUTER_SCIENCE: 'Technology/Computer Science',
    PARENTING_FAMILY: 'Parenting/Family',
    RELATIONSHIPS: 'Relationships',
    SPORTS: 'Sports',
    TRUE_CRIME: 'True Crime',
    ESSAYS: 'Essays',
    ANTHOLOGIES: 'Anthologies',
    REFERENCE_GUIDES: 'Reference/Guides',
    EDUCATION: 'Education',
    ENVIRONMENTAL_CLIMATE: 'Environmental/Climate',
    CULTURAL_SOCIAL_ISSUES: 'Cultural/Social Issues',
    LITERARY_CRITICISM: 'Critiques on Literature',
    SHORT_STORY_COLLECTIONS: 'Short Story Collections',
    CLASSIC_LITERATURE: 'Classic Literature',
};

export default BookCategories;